import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule  } from '@angular/forms';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ManageBlogsComponent } from './manage-blogs/manage-blogs.component';
import { ManageCategoriesComponent } from './manage-categories/manage-categories.component';
import { ManagePagesComponent } from './manage-pages/manage-pages.component';
import { BlogFormComponent } from './blog-form/blog-form.component';
import * as CanvasJSAngularChart from '../../assets/csv/canvasjs.angular.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MutualfundsComponent } from './mutualfunds/mutualfunds.component';
import { GoldBondComponent } from './gold-bond/gold-bond.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AdminRoutingModule
  ],
  declarations: [
    AdminComponent,
    AdminDashboardComponent,
    ManageBlogsComponent,
    ManageCategoriesComponent,
    ManagePagesComponent,
    BlogFormComponent,
    CanvasJSChart,
    SpinnerComponent,
    MutualfundsComponent,
    GoldBondComponent
  ]
})
export class AdminModule { }
