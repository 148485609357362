import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadService } from '../../services/download.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(private router: Router,
    private downloadService: DownloadService) { }
  href;
  public isActive:boolean = true;
  name = localStorage.getItem('currentUser');
  ngOnInit() {
    this.href = this.router.url;
    if (!localStorage.getItem('load')) { 
      localStorage.setItem('load', 'no reload') 
      location.reload() 
    } else {
      localStorage.removeItem('load') 
    }
  }

  getNomineePDF(){
    let val = JSON.parse(this.name).fileName;
    this.downloadService.getNomineePDF(val).subscribe((blob: Blob): void => {
      const file = new Blob([blob], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      var a = document.createElement('a');
          a.href = fileURL; 
          a.target= '_blank';
          a.download= 'nominee-card-'+JSON.parse(this.name).username+'.pdf';
          document.body.appendChild(a);
          a.click();
    });
  }

}
