import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PortfolioDataServiceService } from '../../services/portfolio-data-service.service';

@Component({
  selector: 'app-mutualfunds',
  templateUrl: './mutualfunds.component.html',
  styleUrls: ['./mutualfunds.component.css']
})
export class MutualfundsComponent implements OnInit {

  title = 'Mutual Funds List';
  mutualfunds = [];
  error: string;
  name = localStorage.getItem('currentUser');
  investment;
  current;
  pnl;
  pnlFlag;
  pnlPercent;
  categoriesDeatils;
  firstLoad:number = 8;
  viewBtn:string = 'View All Stocks';
  loader = false;

  constructor(private portfolioDataServiceService: PortfolioDataServiceService) { }

  ngOnInit() {
    this.loader = true;
    let csvName = JSON.parse(this.name).fileName + '_mf_holdings.csv';
    this.portfolioDataServiceService.getStock(JSON.parse(this.name).fileName+'/'+ csvName)
    .subscribe((response: any) => {
      let csvRowData = response.split(/[\r?\n|\r|\n]+/);
      var data = [];
      let catVal = [];
      let sumOfInvestment = 0;
      let sumOfCurrentAmount = 0;
      csvRowData.forEach((rowData: any, index: number) => {
        let values;
        let dataVal = [];
        values = rowData.split(',');
        if (values[0] !== undefined) {
          if (index === 0) return;
          if(!catVal.find(o => o.category === values[1])) {
            catVal.push({'category':values[1],'invested':parseFloat(values[3]),'currentAmount':parseFloat(values[4]),'total':1});
          } else {
            if(catVal.find(o => o.category === values[1])) {
              catVal.find(o => o.category === values[1]).total = catVal.find(o => o.category === values[1]).total + 1;
              catVal.find(o => o.category === values[1]).invested = parseFloat(catVal.find(o => o.category === values[1]).invested) + parseFloat(values[3]);
              catVal.find(o => o.category === values[1]).currentAmount = parseFloat(catVal.find(o => o.category === values[1]).currentAmount) + parseFloat(values[4]);
            }
          }
          if (values[0] !== undefined) {
            dataVal.push({'name':values[0]});
          }
          if (values[1] !== undefined) {
            dataVal.push({'category':values[1]});
          }
          if (values[2] !== undefined) {
            dataVal.push({'folio':values[2]});
          }
          if (values[3] !== undefined) {
            dataVal.push({'invest':parseFloat(values[3]).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })});
          }
          if (values[4] !== undefined) {
            dataVal.push({'currentValue':parseFloat(values[4]).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })});
          }
          if (values[5] !== undefined) {
            dataVal.push({'unit':values[5]});
          }
          if (values[6] !== undefined) {
            dataVal.push({'nav':values[6]});
          }
          if (values[7] !== undefined) {
            dataVal.push({'avgAmount':values[7]});
          }
          if (values[8] !== undefined) {
            dataVal.push({'totalReturnPer':values[8]});
          }
          if (values[9] !== undefined) {
            dataVal.push({'xrr':values[9]});
          }
          if (values[3] !== undefined && values[4] !== undefined) {
            sumOfInvestment += parseFloat(values[3]);
            sumOfCurrentAmount += parseFloat(values[4]);
            let cal;
            cal = parseFloat(values[4]) - parseFloat(values[3]);
            let perOfpnl = (cal*100)/parseFloat(values[4]);
            dataVal.push({'pnlVal':cal.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })});
            if (cal > 0) {
              dataVal.push({'pnlValFlag': true})
            } else {
              dataVal.push({'pnlValFlag': false})
            }
            dataVal.push({'perPnL':Number(perOfpnl).toFixed(2)})
            dataVal.push({'perPnLPositive':Math.abs(perOfpnl).toFixed(2)}) 
          }
          if (values[10] !== undefined) {
            dataVal.push({'brokerLogoText':values[10]});
            let imagePath = "../../../assets/img/broker-logo/"+values[10]+'-logo.svg';
            dataVal.push({'brokerLogo':imagePath});
            if(values[10] === 'ku') {
              dataVal.push({'brokerName':'Kuvera'});
            } else if (values[10] === 'kt') {
              dataVal.push({'brokerName':'Kite by Zerodha'});
            } else if (values[10] === 'up') {
              dataVal.push({'brokerName':'UpStox'});
            } else if (values[10] === 'ag') {
              dataVal.push({'brokerName':'AngelOne'});
            }
          }
          data.push(dataVal);
        }
      })
      var calIndex = data.length - 1;
      
      catVal.forEach( function(value,i) { 
        let allocation = Math.round(value.invested * 100 / (sumOfInvestment));
        catVal[i]['allocation'] = allocation;
        catVal[i]['invested'] = value.invested.toLocaleString('en-IN', {
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'INR'
        });
        catVal.sort((a, b) => a['allocation'] < b['allocation'] ? 1 : a['allocation'] > b['allocation'] ? -1 : 0);
      });
      console.log('sumOfInvestment',sumOfInvestment);
      console.log('sumOfCurrentAmount',sumOfCurrentAmount);
      this.categoriesDeatils = catVal;
      this.current = sumOfCurrentAmount.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      this.investment = sumOfInvestment.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      this.pnl = (sumOfCurrentAmount - sumOfInvestment).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      if((sumOfCurrentAmount - sumOfInvestment) > 0) {
        this.pnlFlag = true;
      } else {
        this.pnlFlag = false;
      }
      let perCent = Math.round(((sumOfCurrentAmount - sumOfInvestment)*100)/sumOfInvestment);
      this.pnlPercent = perCent;
      this.mutualfunds = data;
    });
    this.loader = false;
  }

  loadMore (val) {
    this.loader = true;
    if(val === 'View All Stocks') {
      this.firstLoad = this.mutualfunds.length;
      this.viewBtn = 'Hide Stocks';
    } else {
      this.firstLoad = 10;
      this.viewBtn = 'View All Stocks';
    }
    this.loader = false;
  }
}
