import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PortfolioDataServiceService } from '../../services/portfolio-data-service.service';

@Component({
  selector: 'app-manage-blogs',
  templateUrl: './manage-blogs.component.html',
  styleUrls: ['./manage-blogs.component.css']
})
export class ManageBlogsComponent implements OnInit {

  title = 'Stocks List';
  stocks = [];
  error: string;
  name = localStorage.getItem('currentUser');
  investment;
  current;
  pnl;
  pnlFlag;
  pnlPercent;
  categoriesDeatils;
  firstLoad:number = 8;
  viewBtn:string = 'View All Stocks';
  stockKeyName = [];
  loader;

  constructor(private portfolioDataServiceService: PortfolioDataServiceService) { }

  ngOnInit() {
    this.stockData();
  }

  stockData() {
    this.loader = true;
    let csvName = JSON.parse(this.name).fileName + '_holdings.csv';
    this.portfolioDataServiceService.getStock(JSON.parse(this.name).fileName+'/'+ csvName)
    .subscribe((response: any) => {
      let csvRowData = response.split(/[\r?\n|\r|\n]+/);
      var data = [];
      let catVal = [];
      let sumOfInvestment = 0;
      let sumOfCurrentAmount = 0;
      let stkName=[];
      csvRowData.forEach((rowData: any, index: number) => {
        let values;
        let dataVal = [];
        values = rowData.split(',');
        if (values[0] !== undefined && values[9] !== 's') {
          if (index === 0) return;
          if(!catVal.find(o => o.category === values[10])) {
            let sumOfAvgTGetInvest = values[2] * values[1];
            catVal.push({'category':values[10],'invested':parseFloat(sumOfAvgTGetInvest.toString()),'total':1});
          } else {
            if(catVal.find(o => o.category === values[10])) {
              let sumOfAvgTGetInvest = values[2] * values[1];
              catVal.find(o => o.category === values[10]).total = catVal.find(o => o.category === values[10]).total + 1;
              catVal.find(o => o.category === values[10]).invested = parseFloat(catVal.find(o => o.category === values[10]).invested) + parseFloat(sumOfAvgTGetInvest.toString());
            }
          }
          if (values[0] !== undefined) {
            dataVal.push({'name':values[0]});
          }
          if (values[1] !== undefined) {
            dataVal.push({'qty':values[1]});
          }
          if (values[2] !== undefined) {
            dataVal.push({'avgCost':parseFloat(values[2]).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })});
          }
          if (values[3] !== undefined) {
            dataVal.push({'ltp':parseFloat(values[3]).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })});
          }
          if (values[4] !== undefined) {
            dataVal.push({'currentValue':parseFloat(values[4]).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })});
          }
          if (values[5] !== undefined) {
            dataVal.push({'pnl':values[5]});
          }
          if (values[6] !== undefined) {
            dataVal.push({'netChange':values[6]});
          }
          if (values[7] !== undefined) {
            dataVal.push({'dayChange':values[7]});
          }
          if (values[2] !== undefined && values[1] !== undefined) {
            let sumOfAvgTGetInvest = values[2] * values[1];
            sumOfInvestment += parseFloat(sumOfAvgTGetInvest.toString());
            dataVal.push({'invest':parseFloat(sumOfAvgTGetInvest.toString()).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })});
          }
          if (values[9] !== undefined) {
            dataVal.push({'sgbFlag':values[9]});
          }
          if (values[2] !== undefined && values[1] !== undefined && values[4] !== undefined) {
            // sumOfInvestment += parseFloat(values[8]);
            sumOfCurrentAmount += parseFloat(values[4]);
            let cal;
            let sumOfAvgTGetInvest = parseFloat(values[2]) * parseFloat(values[1]);
            cal = parseFloat(values[4]) - sumOfAvgTGetInvest;
            let perOfpnl = (cal*100)/parseFloat(values[4]);
            dataVal.push({'pnlVal':cal.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })});
            if (cal > 0) {
              dataVal.push({'pnlValFlag': true})
            } else {
              dataVal.push({'pnlValFlag': false})
            }
            dataVal.push({'perPnL':Number(perOfpnl).toFixed(2)})
            dataVal.push({'perPnLPositive':Math.abs(perOfpnl).toFixed(2)}) 
          }
          if (values[10] !== undefined) {
            dataVal.push({'category':values[10]});
          }
          if (values[11] !== undefined) {
            let imagePath = "../../../assets/img/broker-logo/"+values[11]+'-logo.svg';
            dataVal.push({'brokerLogo':imagePath});
            if(values[11] === 'ku') {
              dataVal.push({'brokerName':'Kuvera'});
            } else if (values[11] === 'kt') {
              dataVal.push({'brokerName':'Kite by Zerodha'});
            } else if (values[11] === 'up') {
              dataVal.push({'brokerName':'UpStox'});
            } else if (values[11] === 'ag') {
              dataVal.push({'brokerName':'AngelOne'});
            }
          }
          if(values[12] !== undefined) {
            stkName.push(values[12]);
            dataVal.push({'symbol':values[12]});
          }
          data.push(dataVal);
        }
      })
      var calIndex = data.length - 1;
      catVal.forEach( function(value,i) {
        let allocation = Math.round(value.invested * 100 / (sumOfInvestment));
        catVal[i]['allocation'] = allocation;
        catVal[i]['invested'] = value.invested.toLocaleString('en-IN', {
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'INR'
        });
        catVal.sort((a, b) => a['allocation'] < b['allocation'] ? 1 : a['allocation'] > b['allocation'] ? -1 : 0);
      });
      data.forEach( function(value,i) {
        // let sumOfAvgTGetInvest = parseFloat(value[2]) * parseFloat(value[1]);
        let sumOfAvgTGetInvest = (Number(value[2].avgCost.replace(/[^0-9\.-]+/g,""))) * parseFloat(value[1].qty);
        let allocation = Math.round((Number(sumOfAvgTGetInvest.toString().replace(/[^0-9\.-]+/g,"")) * 100 / (sumOfInvestment)));
        data[i]['allocation'] = allocation;
      });
      this.categoriesDeatils = catVal;
      this.investment = sumOfInvestment.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      this.stockDetailsLive(stkName.toString(),data);
    });
  }

  loadMore (val) {
    this.loader = true;
    if(val === 'View All Stocks') {
      this.firstLoad = this.stocks.length;
      this.viewBtn = 'Hide Stocks';
    } else {
      this.firstLoad = 10;
      this.viewBtn = 'View All Stocks';
    }
    this.loader = false;
  }

  stockDetailsLive(val,data) {
    let sumOfInvestment=0;
    let sumOfCurrentAmount =0;
    this.portfolioDataServiceService.getStoclDetailsLive(val).subscribe((response: any) => {
      var dataVal = JSON.stringify(response);
      response = dataVal;
      data.forEach( function(value,i) {
        let sumOfAvgTGetInvest = (Number(value[2].avgCost.replace(/[^0-9\.-]+/g,""))) * parseFloat(value[1].qty);
        let invested = sumOfAvgTGetInvest;
        var stockFilterVal = JSON.parse(response).find(x => x.symbol === data[i][17].symbol);
        // console.log('stockFilterVal',stockFilterVal);
        if(stockFilterVal){
          if((stockFilterVal.symbol === data[i][17].symbol)) {
            value[3].ltpLT = (Number(stockFilterVal.lastPrice.replace(/[^0-9\.-]+/g,"")));
          } else {
            value[3].ltpLT = 0;
          }
        } else {
          value[3].ltpLT = 0;
        }
        
        value[4].currentAmount = (value[3].ltpLT * value[1].qty);
        if(stockFilterVal && stockFilterVal.companyName) {
          data[i][0].name = stockFilterVal.companyName;
        }
        if (invested !== undefined && value[4].currentAmount !== undefined) {
          sumOfInvestment += invested;
          sumOfCurrentAmount += value[4].currentAmount;
          let cal;
          cal = parseFloat(value[4].currentAmount) - invested;
          let perOfpnl = (cal*100)/parseFloat(value[4].currentAmount);
          data[i].push({'pnlValLT':cal.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
          })});
          value[4].currentAmount = value[4].currentAmount.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
          });
          value[3].ltpLT = value[3].ltpLT.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
          });
          if (cal > 0) {
            data[i].push({'pnlValFlagLT': true})
          } else {
            data[i].push({'pnlValFlagLT': false})
          }
          data[i].push({'perPnLLT':Number(perOfpnl).toFixed(2)})
          data[i].push({'perPnLPositiveLT':Math.abs(perOfpnl).toFixed(2)}) 
        }
      });
      this.current = sumOfCurrentAmount.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      this.pnl = (sumOfCurrentAmount - sumOfInvestment).toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      if((sumOfCurrentAmount - sumOfInvestment) > 0) {
        this.pnlFlag = true;
      } else {
        this.pnlFlag = false;
      }
      let perCent = Math.round(((sumOfCurrentAmount - sumOfInvestment)*100)/sumOfInvestment);
      this.pnlPercent = perCent;
      this.stocks = data;
      this.loader = false;
    }, error => {
      this.loader = false;
    });
  }
}
