import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loggedInUser:string;
  constructor( private titleService: Title, private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    // this.loggedInUser = JSON.parse(localStorage.getItem('currentUser')).first_name + ' ' + (JSON.parse(localStorage.getItem('currentUser')).last_name ? JSON.parse(localStorage.getItem('currentUser')).last_name : '');
    this.loggedInUser = JSON.parse(localStorage.getItem('currentUser')).first_name;
  }

  get isLoggedIn() { return this.authService.isLoggedIn(); }

  setPageTitle(title: string) {
    title = 'mydeePockets - The PMS Service'
    this.titleService.setTitle(title);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
