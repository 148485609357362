import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PortfolioDataServiceService } from '../../services/portfolio-data-service.service';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements AfterViewInit {

  title = 'Dashboard';
  chart: any;
  dataPoints: any = [];
  showChart: Boolean = false;
  name = localStorage.getItem('currentUser');
  totalPortfolio = [];
  portfolioProduct = ['','Mutual Fund','Stock','Sovereign Gold Bond'];
  stocksValue: string;
  mutualFundsValue: string;
  totalValueOfPortfolio: string;
  sgbValues:string;
  totalInvested = [];
  totalInvestedVal:string;
  mfInvestment:string;
  stockInvestment:string;
  sgbInvetsment:string;
  pnl:string;
  pnlFlag:boolean = false;
  mfData = [];
  stockData = [];
  loader:boolean = false;
  sgbInvestView = false;
  stockInvestView = false;
  mfInvestView = false;
  nseIndex = [];
  indexFlag:boolean;
  constructor(private http: HttpClient,
    private portfolioDataServiceService: PortfolioDataServiceService,) { }
  chartOptions = {
    animationEnabled: true,
    theme: 'light2', //"light2", "dark1", "dark2"
    title: {
      text: '',
      fontSize: 18,
      fontWeight: "bold",
      fontColor: "#000000",
      margin: 20,
    },
    axisX: {
      reversed: true,
    },
    axisY: {
      title: 'Marks Scored (Out of 100)',
      includeZero: true,
    },
    data: [
      {
        type: "pie",
        // neckHeight: 1,
        indexLabelFormatter: function(e){
          if(e.percent > 0) {
            return e.dataPoint.label + " " + e.dataPoint.y.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            }) ;
          }			
        },
        dataPoints: this.dataPoints,
      },
    ],
  };

  getChartInstance(chart: object) {
    this.chart = chart;
  }

  ngOnInit() {
    this.loader = true;
    this.showLoaderLongTimeAction();
    this.getIndexes();
    this.getMarketStatus();
  }

  ngAfterViewInit() {
    this.getStockCSVData();
    this.getMfCSVData();
  }

  getMarketStatus() {
    this.portfolioDataServiceService.getStockMarketStatus().subscribe((response: any) => {
      var self = this;
      if(response) {
        setInterval(function () {
          console.log('response',response);
          self.getIndexes();
        }, 120000);
      }
    });
  }

  getIndexes() {
    this.portfolioDataServiceService.getStockIndex().subscribe((response: any) => {
      var dataVal = JSON.stringify(response);
      response = dataVal;
      this.nseIndex = JSON.parse(response)['data'][0];
      if(this.nseIndex) {
        let upDown = (Number(this.nseIndex['last'].replace(/[^0-9\.-]+/g,""))) - (Number(this.nseIndex['previousClose'].replace(/[^0-9\.-]+/g,"")));
        this.nseIndex['point'] = upDown.toFixed(2);
        if (upDown > 0) { 
          this.indexFlag = true;
        } else {
          this.indexFlag = false;
        }
      }
    });
  }

  stockDetailsLive(val,data) {
    let sumOfInvestment=0;
    let sumOfCurrentAmount =0;
    let sumOfSGBStock = 0;
    this.portfolioDataServiceService.getStoclDetailsLive(val).subscribe((response: any) => {
      var dataVal = JSON.stringify(response);
      response = dataVal;
      data.forEach( function(value,i) {
        let sumOfAvgTGetInvest = (Number(value.avgCost.replace(/[^0-9\.-]+/g,""))) * parseFloat(value.qty);
        let invested = sumOfAvgTGetInvest;
        var stockFilterVal = JSON.parse(response).find(x => x.symbol === value.symbol);
        let lastPrice = 0;
        if(stockFilterVal){
          if((stockFilterVal.symbol === value.symbol)) {
            lastPrice = (Number(stockFilterVal.lastPrice.replace(/[^0-9\.-]+/g,"")));
          } else {
            lastPrice = 0;
          }
        } else {
          lastPrice = 0;
        }
        value.currentAmount = (lastPrice * value.qty);
        if (invested !== undefined && value.currentAmount !== undefined) {
          sumOfInvestment += invested;
          if(value.sgb === 's') {
            sumOfSGBStock += parseFloat(value.currentAmount) || 0;
          } else {
            sumOfCurrentAmount += parseFloat(value.currentAmount) || 0;;
          }
        }
        if(stockFilterVal && stockFilterVal.companyName) {
          data[i].name = stockFilterVal.companyName;
        }
        data[i].currentAmount = value.currentAmount.toLocaleString('en-IN', {
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'INR'
        });
      });
      this.stockData = data;
      this.stocksValue = sumOfCurrentAmount.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      this.sgbValues = sumOfSGBStock.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      this.totalPortfolio.push(sumOfCurrentAmount);
      this.totalPortfolio.push(sumOfSGBStock);
      if (this.totalPortfolio.length > 1) {
        this.makeGraph();
      }
    })
  }

  getStockCSVData() {
    this.loader = true;
    let csvName = JSON.parse(this.name).fileName + '_holdings.csv';
    var stkName=[];
    this.portfolioDataServiceService.getStock(JSON.parse(this.name).fileName+'/'+ csvName).subscribe((response: any) => {
      let csvRowData = response.split(/[\r?\n|\r|\n]+/);
      let sumOfStock = 0;
      let sumOfSGBStock = 0;
      let sumOfAvgVal = 0;
      let sumOfAvgSgbVal=0;
      let amount;
      let stockData = [];
      csvRowData.forEach((rowData: any, index: number) => {
        if (index === 0) return;
        var data = rowData.split(',');
        if (data[0] !== '' && data[1] !== '' && data[2] !== '') {
          if(data[9] === 's') {
            sumOfAvgSgbVal += (Number(data[2].replace(/[^0-9\.-]+/g,""))) * parseFloat(data[1]) || 0;
          } else {
            let sumOfAvgTGetInvest = (Number(data[2].replace(/[^0-9\.-]+/g,""))) * parseFloat(data[1]);
            let invested = sumOfAvgTGetInvest;
            sumOfStock += parseFloat(data[4]) || 0;
            sumOfAvgVal += invested || 0;
          }
          if(data[12] !== undefined) {
            stkName.push(data[12]);
          }
          amount = parseFloat(data[4]).toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
          });
          stockData.push({'name':data[0],
                                'qty':data[1],
                                'avgCost':data[2],
                                'currentAmount':data[4],
                                'symbol':data[12],
                                'amount': amount,
                                'sgb':data[9]
                              });
        }
      });
      this.stockDetailsLive(stkName.toString(),stockData);
      this.totalPortfolio.push(sumOfSGBStock);
      this.totalInvested.push(sumOfAvgVal);
      this.totalInvested.push(sumOfAvgSgbVal);
      this.stockInvestment = sumOfAvgVal.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      this.sgbInvetsment = sumOfAvgSgbVal.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
    }, error => {
      this.stocksValue = '0';
      this.sgbValues = '0';
      this.totalPortfolio.push('0');
      this.totalPortfolio.push('0');
      this.totalInvested.push('0');
    });
  }

  getMfCSVData() {
    this.loader = true;
    let mfName = JSON.parse(this.name).fileName + '_mf_holdings.csv';
    this.portfolioDataServiceService.getStock(JSON.parse(this.name).fileName+'/' + mfName).subscribe((response: any) => {
      let csvRowData = response.split(/[\r?\n|\r|\n]+/);
      let sumOfMF = 0;
      let sumOfAvgVal = 0;
      let amount;
      csvRowData.forEach((rowData: any, index: number) => {
        if (index === 0) return;
        var data = rowData.split(',');
        if (data[1] !== undefined || data[2] !== undefined) {
          sumOfMF += parseFloat(data[4]) || 0;
          sumOfAvgVal += parseFloat(data[3]) || 0;
          amount = parseFloat(data[3]).toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
          });
          this.mfData.push({'name':data[0],'amount': amount});
        }
      });
      this.mutualFundsValue = sumOfMF.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
      var total_investment_mf = sumOfMF;
      this.totalPortfolio.push(total_investment_mf);
      this.totalInvested.push(sumOfAvgVal);
      this.mfInvestment = sumOfAvgVal.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
      });
    }, error => {
      this.mutualFundsValue = '0';
      this.totalPortfolio.push('0');
      this.totalInvested.push('0');
      this.makeGraph();
    });
  }

  makeGraph() {
    this.loader = true;
    let sumOfTotal = 0;
    let totalInvested = 0;
    let pnlVal =0;
    this.totalPortfolio.forEach((rowData: any, index: number) => {
      this.dataPoints.push({ x: this.portfolioProduct[index], y: parseFloat(rowData), label: this.portfolioProduct[index] });
      sumOfTotal += parseFloat(rowData) || 0;
    });
    this.totalValueOfPortfolio = sumOfTotal.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
    });
    this.totalInvested.forEach((rowData: any) => {
      totalInvested += parseFloat(rowData) || 0;
    });
    this.totalInvestedVal = totalInvested.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
    });
    pnlVal = sumOfTotal - totalInvested;
    this.pnl = pnlVal.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
    });
    if (pnlVal > 0) {
      this.pnlFlag = true;
    }
    this.showChart = true;
    this.loader = false;
  }

  showLoaderLongTimeAction(){
    var self = this;
    setTimeout(function(){
      if(self.loader) {
        window.location.reload()
      }
    },3000);
  }

  invested(clickedVal) {
    if(clickedVal === 'sgb') {
      this.sgbInvestView = true;
    } else if (clickedVal === 'stock') {
      this.stockInvestView = true;
    } else if (clickedVal === 'mf') {
      this.mfInvestView = true;
    }
  }
}
