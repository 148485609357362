import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin/admin.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ManageBlogsComponent } from './manage-blogs/manage-blogs.component';
import { ManageCategoriesComponent } from './manage-categories/manage-categories.component';
import { ManagePagesComponent } from './manage-pages/manage-pages.component';
import { BlogFormComponent } from './blog-form/blog-form.component';
import { MutualfundsComponent } from './mutualfunds/mutualfunds.component';
import { GoldBondComponent } from './gold-bond/gold-bond.component';

import { AuthGuard } from '../auth/auth.guard';

const routes: Routes = [
  {
    path: 'app',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
      path: '',
      children: [
        { path: 'stocks', component: ManageBlogsComponent },
        { path: 'calculator/sip', component: BlogFormComponent },
        { path: 'blogs/edit/:id', component: BlogFormComponent },
        { path: 'calculator/lumpsum', component: ManageCategoriesComponent },
        { path: 'contact', component: ManagePagesComponent },
        { path: 'dashboard', component: AdminDashboardComponent },
        { path: 'mutualfunds', component: MutualfundsComponent },
        { path: 'sgb', component: GoldBondComponent }
      ],
    }
  ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
