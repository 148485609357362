import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-form',
  templateUrl: './blog-form.component.html',
  styleUrls: ['./blog-form.component.css']
})
export class BlogFormComponent implements OnInit {
  chart: any;
  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: string;
  timeRangeVal;
  returnPercentVal;
  totalInvestmentAmount;
  totalValuation;
  expectedReturnAmount;
  blogForm: FormGroup;
  submitAction: boolean;
  dataPoints: any = [];
  showChart: Boolean = false;
  chartOptions = {
    animationEnabled: true,
    theme: 'light1', //"light2", "dark1", "dark2"
    title: {
      text: '',
      fontSize: 18,
      fontWeight: "bold",
      fontColor: "#000000",
      fontFamily : '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      padding : {
        top: 20,
        right: 0,
        bottom: 50,
        left: 0
      },
    },
    data: [
      {
        type: "doughnut",
        indexLabel: "{name}: {y}",
        innerRadius: "50%",
        yValueFormatString: "'₹'#,##,##,##,##0.00",
        dataPoints: this.dataPoints,
      },
    ],
  };
  constructor(
    private fb: FormBuilder,
    private blogService: BlogService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.blogForm = this.fb.group({
      id: [''],
      investAmount: ['', Validators.required],
      returnPercentVal: ['6'],
      timeRangeVal: ['1'],
    });
    this.timeRangeVal = 1;
    this.returnPercentVal = 6;
  }

  getChartInstance(chart: object) {
    console.log('chart',chart);
    this.chart = chart;
  }

  updateTextInput(val,flag) {
    if(flag === 'time') {
      this.timeRangeVal = val;
    } else if (flag === 'return') {
      this.returnPercentVal = val;
    }
  }

  submit(setClick) {
    this.chartOptions.data[0].dataPoints = []
    this.dataPoints = [];
    let investmentAmount = this.blogForm.get('investAmount').value;
    let timeDuration = this.timeRangeVal;
    var months = timeDuration * 12;  //Time period 
    let expectedReturnPercent = this.returnPercentVal;
    let totalInvestmentAmount = investmentAmount * months;
    this.totalInvestmentAmount = totalInvestmentAmount.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
    });
    let futureValue = 0;
    let monthlyRate = expectedReturnPercent / 12 / 100;  //Rate of interest
    
    futureValue = investmentAmount * (Math.pow(1 + monthlyRate, months) - 1) / monthlyRate;
    this.totalValuation = futureValue.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
    });
    this.dataPoints.push({y: totalInvestmentAmount, name: 'Total Investment' });
    let expectedReturnAmount = futureValue - totalInvestmentAmount;
    console.log('expectedReturnAmount',expectedReturnAmount);
    this.expectedReturnAmount = expectedReturnAmount.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
    });
    this.dataPoints.push({ y: expectedReturnAmount, name: 'Return Value' });
    if(this.dataPoints.length) {
      this.chartOptions.data[0].dataPoints = this.dataPoints;
      this.showChart = true;
      this.chart.render();
    } else {
      this.showChart = false;
    }
  }

  investAmountValChange(value) {
    if(value >=1 ){
      this.submitAction = true;
    } else {
      this.submitAction = false;
    }
  }
}
