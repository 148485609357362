import { Component } from '@angular/core';
import { NavigationEnd, Router, UrlSegment } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'mydeePockets - The PMS Service';
  loginPage:boolean;
  constructor(public router: Router) {}
  ngOnInit() {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          let link = this.router.url.split("?");
          if(link[0] == '/login' || link[0] == '/') {
            this.loginPage = true;
          } else {
            this.loginPage = false;
          }
        }
      }
    );
  }
}
